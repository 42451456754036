/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var _ = {};

_.now = Date.now || function() {
	return new Date().getTime();
};

_.throttle = function(func, wait, options) {
	var timeout, context, args, result;
	var previous = 0;
	if (!options) options = {};

	var later = function() {
		previous = options.leading === false ? 0 : _.now();
		timeout = null;
		result = func.apply(context, args);
		if (!timeout) context = args = null;
	};

	var throttled = function() {
		var now = _.now();
		if (!previous && options.leading === false) previous = now;
		var remaining = wait - (now - previous);
		context = this;
		args = arguments;
		if (remaining <= 0 || remaining > wait) {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
			previous = now;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
		} else if (!timeout && options.trailing !== false) {
			timeout = setTimeout(later, remaining);
		}
		return result;
	};

	throttled.cancel = function() {
	clearTimeout(timeout);
	previous = 0;
	timeout = context = args = null;
	};

	return throttled;
};

function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+) => return version number
       return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Newsco = {
		// All pages
		'common': {
			init: function() {
				// Responsive bootstrap toolkit
				var viewport = ResponsiveBootstrapToolkit;
				var iev = detectIE();

				if(!iev || iev > 11) {
					//imgix
					imgix.fluid();
				}

				// Inject svg's for styling
                new SVGInjector(document.querySelectorAll('img.svg'));

                if(window.HMC) {
                	// Er gebeurt hier niets met cookies, maar wel netjes om weg te halen aangezien de twitter knop wel werkt met twitter script
                	if(window.consent_level() < 3) {
                		$('.article__footer').remove();
                	}
                }
                                
				function handleNewsletterForm($form, $statusActiveStyle) {
					var $emailInput = $form.find('input[type=email]'),
						$submitButton = $form.find('input[type=submit]').prop('disabled', true),
						$status = $form.find('.form-status').text('Een momentje geduld...').css($statusActiveStyle),
						email = $emailInput.val();

					// If email is entered
					if(email) {
						$.when($.post(WPURLS.ajaxurl, {'action' : 'subscribe_to_list', 'email' : email}))
						.done(function(data) {
							$submitButton.prop('disabled', false);
							if(data.success) {
								$emailInput.val('');
							}
							$status.text(data.data.status_message);
						});
					} else {
						$status.text('Vul a.u.b. uw e-mailadres in.');
					}
				}

				// Newsletter in footer
				$('.newsletter-bar__form').on('submit', function(e) {
					handleNewsletterForm($(this), {'display': 'inline'});
					e.preventDefault();
				});

				$('.sidebar-widget-newsletter').each(function() {
					$('.sidebar-widget-newsletter__form').on('submit', function(e) {
						handleNewsletterForm($(this), {'display': 'block'});
						e.preventDefault();
					});
				});

				// Sharing
				$('.share').on('click', '.open-window', function(e) {
					var sTop = window.screen.height/2-(218);
					var sLeft = window.screen.width/2-(313);
					var win = window.open($(this).attr('href'),'sharer','toolbar=0,status=0,resizable=0,location=0,width=626,height=256,top='+sTop+',left='+sLeft);
					e.preventDefault();
				});

				// Cookie warning
				var cookieWarning = Cookies.get('cookie-warning');

				if(typeof cookieWarning === 'undefined') {
					// Cookie for cookie warning is not set
					$('.cookie-warning').addClass('active');
				}

				$('.cookie-warning').on('click', '.cookie-warning__close-btn, .cookie-warning__close', function(e) {
					$('.cookie-warning').removeClass('active');
					Cookies.set('cookie-warning', 'accepted', { expires: 365 });

					e.preventDefault();
				});

				// Special notice cookie
				$('.special-notice').each(function(i, e) {
					var $el = $(this);
						id = $el.attr('data-url');

					var cookieSpecialNotice = Cookies.get('cookie-special-notice#' + id);

					if(typeof cookieSpecialNotice === 'undefined') {
						$el.addClass('active');
					}

					$el.on('click', '.special-notice__close', function(e) {
						$el.removeClass('active');
						Cookies.set('cookie-special-notice#' + id, 'closed', { expires: 7 });

						e.preventDefault();
					});
				});

				// Page menu
				var $pageMenu = $('.page-submenu__list');

				function mobilizePageMenu($menu) {
					if(viewport.is('<md')) {
						if( ! $pageMenu.hasClass('collapse') ) {
							$pageMenu.addClass('collapse');
						}
					} else {
						$pageMenu.removeClass('collapse');
						$pageMenu.css({ 'height' : 'auto' });
					}
				}

				mobilizePageMenu($pageMenu);

				$(window).resize(
					viewport.changed(function() {
						mobilizePageMenu($pageMenu);

						if(viewport.is('<md')) {
							$('.comments').insertBefore('.single-related');
						} else {
							$('.comments').insertAfter('.single-related');
						}
					}, 150)
				);

				if(viewport.is('<md')) {
					$('.comments').insertBefore('.single-related');
				} else {
					$('.comments').insertAfter('.single-related');
				}

				$('.page-article__title--mobile').on('click', function(e) {
					if(viewport.is('<md')) {
						$pageMenu.collapse('toggle');
						$(this).toggleClass('active');
						e.preventDefault();
					}
				});

				var articleExcerptProp = 'min-height';

				// Vies
				if($('body.archive, .search-results, .hmc-website').length) {
					articleExcerptProp = 'height';
				}
				
				// Match height on these element
				$('.posts .article-excerpt').matchHeight({
					property: articleExcerptProp
				});
				$('.posts .author-excerpt').matchHeight({
					property: 'min-height'
				});
				$('.module-fotoverslag-item').matchHeight();
				$('.single-related__posts .article-excerpt').matchHeight({
					property: 'min-height'
				});

				$('.posts .article-excerpt--archive').matchHeight({
					property: 'height'
				});
				

				if (Modernizr.touchevents) {
					$('.main-menu__menu .menu-item-has-children > a').on('click touchstart', function(e) {
						var $el = $(this),
							$parent = $el.parents('.menu-item-has-children');

						if(! $parent.is('.active')) {
							$parent.addClass('active');
							$('.dimmer').addClass('active');
							e.preventDefault();
						}
					});
				} else {
					$('.main-menu__menu .menu-item-has-children').hoverIntent({
						over: function(e) {
							$(this).toggleClass('active');
							$('.dimmer').toggleClass('active');
						},
						out: function(e) {
							$(this).toggleClass('active');
							$('.dimmer').toggleClass('active');
						},
						sensitivity: 12,
						interval: 50
					});
				}

				// Logo menu				
				$('.main-menu-logo').on('click touchstart', '.main-menu-logo__trigger', function(e) {
					var $parent = $(e.delegateTarget);

					$parent.toggleClass('active');

					if(viewport.is('>=sm')) {
						$('.dimmer').toggleClass('active');
					}

					e.preventDefault();
				});

				// Close dimmer and logo menu
				$('.dimmer').on('click touchstart', function() {
					$(this).removeClass('active');
					$('.main-menu-logo, .menu-item-has-children.active').removeClass('active');
				});

				// Search
				$('.search-toggle').on('click', function(e) {
					$('.search-box')
					.addClass('active')
					.find('.search-box__search-field')
					.focus();

					e.preventDefault();
				});
			
				// Mobile menu and search
				var mobileMenu = {
					'state': {
						'menuOpen': false,
						'searchOpen': false
					},
					'toggleMenu': function() {
						this.els.hamburger.toggleClass('active is-active');
						this.els.mobileMenu.toggleClass('active');
						this.state.menuOpen = ! this.state.menuOpen;
					},
					'toggleSearch': function() {
						var $input = this.els.mobileSearch.find('.search-box-mobile__search-field');
						
						this.els.searchToggle.toggleClass('active');
						this.els.mobileSearch.toggleClass('active');
						$('body').toggleClass('mobile-search-active');

						if(this.els.searchToggle.is('.active')) {
							$input.focus();
						} else {
							$input.blur();
						}

						this.state.searchOpen = ! this.state.searchOpen;
					},
					'closeAll': function() {
						this.els.hamburger.removeClass('active is-active');
						this.els.mobileMenu.removeClass('active');
						this.els.mobileSearch.removeClass('active');
						this.els.searchToggle.removeClass('active');
						$('body').removeClass('mobile-search-active');
						this.els.mobileSearch.find('.search-box-mobile__search-field').blur();

						this.state.menuOpen = false;
						this.state.searchOpen = false;
					},
					'init': function() {
						var that = this;

						this.els = {
							'hamburger': $('.hamburger'),
							'searchToggle': $('.main-menu-search-toggle'),
							'mobileMenu': $('.mobile-menu'),
							'mobileSearch': $('.mobile-search')
						};

						// Dimmer
						$('.dimmer').on('click touchstart', function() {
							that.closeAll();
						});

						// Mobile menu
						this.els.hamburger.on('click touchstart', function(e) {
							if(viewport.is('<sm') && !that.state.searchOpen) {
								$('.dimmer').toggleClass('active');
							}

							that.toggleMenu();

							if(that.state.searchOpen) {
								that.toggleSearch();
							}

							e.preventDefault();
						});

						// Mobile search
						this.els.searchToggle.on('click touchstart', function(e) {
							if(viewport.is('<sm') && !that.state.menuOpen) {
								$('.dimmer').toggleClass('active');
							}

							that.toggleSearch();

							if(that.state.menuOpen) {
								that.toggleMenu();
							}

							e.preventDefault();
						});
					}
				};
				mobileMenu.init();

				// Menu sticky
				$(window).on('scroll', _.throttle(function() {
					if(viewport.is('>=sm')) {
						if($(window).scrollTop() > 22) {
							$('body').addClass('desktop-sticky-menu-active');
						} else {
							$('body').removeClass('desktop-sticky-menu-active');
						}
					}
				}, 50))
				.on('resize', _.throttle(function() {
					$('.main-menu-wrapper').height($('.main-menu').outerHeight());
				}, 50)).trigger('resize');

				$('body').on('click', function(e) {
					if ($(e.target).closest('.main-menu__right').length === 0) {
						$('.search-box').removeClass('active');
					}
				});

				// Comment submit
				$('.comment-form').on('submit', function(e) {
					ga('send', 'event', { eventCategory: 'interactie', eventAction: 'reactie'});
				});

				// Lightbox entry photos
				$('.article__entry img').on('click', function(e) {
					var $el = $(this),
						pswpElement = $('.pswp')[0];

					if($el.parent().is('a')) {
						var items = [],
							currentIndex = 0;

						var $hoi = $('.article__entry img').map(function() {
							var $el = $(this);

							if($el.parent().is('a')) {
								return $(this);
							}
						});

						$hoi.each(function(i) {
							var w = $(this).attr('width'),
								h = $(this).attr('height'),
								src = $(this).attr('src'),
								item = {
									src: src,
									w: w,
									h: h,
									title: $(this).parent().siblings('.wp-caption-text').text()
								};

							items.push(item);

							if($el[0] === $(this)[0]) {
								currentIndex = i;
							}
						});

						var options = {
							getThumbBoundsFn: function(index) {
								var thumbnail = $el[0];

								var pageYScroll = window.pageYOffset || document.documentElement.scrollTop; 
								var rect = thumbnail.getBoundingClientRect();

								return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
							},
							index: currentIndex,
							showHideOpacity: true,
							history: false,
							shareEl: false,
							errorMsg: '<div class="pswp__error-msg"><a href="%url%" target="_blank">De afbeelding</a> kon niet worden geladen.</div>'
						};

						var lightBox = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
						
						lightBox.init();

						e.preventDefault();
					}
				});

				// Fotoverslag module
				$('.module-fotoverslag').each(function(i, e) {
					var $el = $(this),
						verslagNumber = $el.attr('data-fotoverslag-number'),
						$fotoCont = $el.find('.module-fotoverslag__fotos'),
						fotoPreviewLength = $fotoCont.find('.module-fotoverslag-foto').length,
						pswpElement = $('.pswp')[0];

					$fotoCont.on('click', '.module-fotoverslag-foto, .module-fotoverslag__more', function(e) {
						var options = {
							getThumbBoundsFn: function(index) {
								var thumbnail;

								// If there is no thumb to animate to
								if(index >= fotoPreviewLength) {
									thumbnail = $fotoCont.find('.module-fotoverslag-item').last()[0];
								} else {
									thumbnail = $fotoCont.find('.module-fotoverslag-foto').eq(index).find('img')[0];
								}

								var pageYScroll = window.pageYOffset || document.documentElement.scrollTop; 
								var rect = thumbnail.getBoundingClientRect();

								return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
							},
							showHideOpacity: true,
							history: false,
							shareEl: false,
							errorMsg: '<div class="pswp__error-msg"><a href="%url%" target="_blank">De afbeelding</a> kon niet worden geladen.</div>'
						};

						if($(this).is('.module-fotoverslag__more')) {
							options.index = 0;
						} else {
							options.index = $(this).index();
						}

						var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, window['fotoverslag_' + verslagNumber], options);
						gallery.init();
						e.preventDefault();
					});
				});
	
				// Module videos
				var $videoModule = $('.module-videos');

				function doVideoModule($module) {
					$module.each(function(i, e) {
						var $el = $(this),
							$overlay = $el.find('.module-videos-overlay'),
							$overlayTitle = $overlay.find('.module-videos-overlay__title a'),
							$firstVideo = $el.find('.articles-list__video-link').eq(0),
							firstVideoId = $firstVideo.attr('data-yt'),
							player,
							mobilePlayer,
							$activeVideoLink = $firstVideo;

						if(viewport.is('>=sm')) {
							$firstVideo.parents('li').addClass('active');
						}

						// Set the first video
						player = new YT.Player($el.find('.ytplayer')[0], {
							height: '390',
							width: '640',
							videoId: firstVideoId,
							playerVars: {
								'autoplay': 0,
								'showinfo': 0,
								'rel': 0
							},
							events: {
								'onStateChange': function(e) {
									if(e.data === 1) {
										$activeVideoLink.parents('li').addClass('playing');
									} else {
										$activeVideoLink.parents('li').removeClass('playing');
									}
								}
							}
							
						});

						mobilePlayer = new YT.Player($el.find('.overlay-ytplayer')[0], {
							height: '390',
							width: '640',
							videoId: firstVideoId,
							playerVars: {
								'autoplay': 0,
								'showinfo': 0,
								'rel': 0
							},
							events: {
								'onStateChange': function(e) {
									if(e.data === 1) {
										$activeVideoLink.parents('li').addClass('playing');
									} else {
										$activeVideoLink.parents('li').removeClass('playing');
									}
								}
							}
							
						});

						// Close the video overlay
						$('.module-videos-overlay__close').on('click', function(e) {
							mobilePlayer.stopVideo();
							$overlay.removeClass('active');
							$activeVideoLink.parents('li').removeClass('active playing');
							e.preventDefault();
						});

						$el.on('click', '.articles-list__video-link', function(e) {
							var $videoLink = $(this),
								$videoTitle = $videoLink.find('.article-excerpt__title').text(),
								permalink = $videoLink.attr('href');

							$activeVideoLink = $videoLink;
							$videoLink.parents('li').siblings().removeClass('active playing').end().addClass('active');

							if(viewport.is('<sm')) {
								$overlay.addClass('active');
								$overlayTitle.text($videoTitle);
								$overlayTitle.attr('href', permalink);

								if (typeof mobilePlayer !== 'undefined') {
									mobilePlayer.loadVideoById($videoLink.attr('data-yt'));
								}
							} else {
								if (typeof player !== 'undefined') {
									player.loadVideoById($videoLink.attr('data-yt'));
								}	
							}

							e.preventDefault();
						});
					});
				}

				window.onYouTubeIframeAPIReady = function() {
					doVideoModule($videoModule);
				};

				if($videoModule.length) {
					var tag = document.createElement('script');
					tag.src = "https://www.youtube.com/player_api";
					var firstScriptTag = document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				}
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Newsco;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
		// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

  	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
